<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept
            :editable="editable"
            label="관리부서"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-process
            :editable="editable"
            label="관련공정"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="SOP_FIRE_FIGHTING_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="sopFireFightingTypeCd"
            label='유형'
            v-model="searchParam.sopFireFightingTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="disposeFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="disposeFlag"
            label="폐기여부"
            v-model="searchParam.disposeFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="소방설비 목록"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <q-btn v-if="editable" class="custom-btn" label="QR코드(이슈점검용)" size="md" icon="qr_code_scanner" color="black">
            <q-popup-proxy>
              <div style="position: sticky;top: 0;left: 0;right: 0;z-index: 99;">
                <q-btn size="sm" icon="print" color="grey" class="full-width" @click.prevent="print" />
              </div>
              <span action="#" id="printJS-form">
                <div class="row" style="width: 800px;">
                  <div v-for="(fireFighting, idx) in grid.data" :key="idx" class="col-3">
                    <q-card flat bordered style="height:220px;">
                      <q-card-section class="bg-primary text-white" style="padding: 0;text-align: center;">
                        <div class="text-h6">{{fireFighting.fireFightingName}} / {{fireFighting.sopFireFightingTypeName}}</div>
                      </q-card-section>
                      <q-separator />
                      <q-card-section horizontal>
                        <q-card-section style="padding: 0;margin: auto;">
                          <vue-qrcode
                            :value="serverName + '/fft/ise/fireFightingIssueCheck?sopFireFightingId=' + fireFighting.sopFireFightingId" 
                            :options="{ width: 150 }"
                            class="canvas"
                          >
                          </vue-qrcode>
                        </q-card-section>
                      </q-card-section>
                    </q-card>
                  </div>
                </div>
              </span>
            </q-popup-proxy>
          </q-btn>
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="openPop" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode';
import printJs from 'print-js'
import selectConfig from '@/js/selectConfig';
export default {
  name: 'fireFightingMaster',
  components: {
    VueQrcode
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        deptCd: null,
        processCd: null,
        sopFireFightingTypeCd: null,
        disposeFlag: 'N'
      },
      disposeFlagItems: [
        { code: 'N', codeName: '미폐기' },
        { code: 'Y', codeName: '폐기' },
      ],
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
          {
            name: 'manageNo',
            field: 'manageNo',
            label: '관리번호',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
          {
            name: 'fireFightingName',
            field: 'fireFightingName',
            label: '소방설비',
            align: 'left',
            sortable: true,
            style: 'width: 250px',
            type: 'link'
          },
          {
            name: 'sopFireFightingTypeName',
            field: 'sopFireFightingTypeName',
            label: '유형',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
          {
            name: 'processName',
            field: 'processName',
            label: '관련공정',
            align: 'left',
            sortable: true,
            style: 'width: 350px',
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '관리부서',
            align: 'left',
            sortable: true,
            style: 'width: 350px',
          },
          {
            name: 'disposeFlag',
            field: 'disposeFlag',
            label: '폐기여부',
            align: 'center',
            sortable: true,
            style: 'width: 70px',
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.fft.fireFighting.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '소방설비 상세';
      this.popupOptions.param = {
        sopFireFightingId: row ? row.sopFireFightingId : '',
      };
      this.popupOptions.target = () => import(`${'./fireFightingMasterDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    print() {
      printJs({
        printable: 'printJS-form',
        type: 'html',
        font_size: '15px',
        targetStyles: ['*'],
      })
    },
  }
};
</script>
